import React from 'react'
import ReactDOM from 'react-dom'

import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Visualizations } from "./components/visualizations";
import { County } from "./components/county";
import { Statement } from "./components/statement";
import { Tweets } from "./components/tweets";
import { Videos } from "./components/videos";
import { Finances } from "./components/finances";
import { TooMuchRegulation } from "./components/too_much_regulation";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";

import "./App.css";

import ReactGA from 'react-ga4';


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});


    ReactGA.initialize('G-4VW1VKH4CC');

  const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);


  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default App;
