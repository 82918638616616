import React from 'react'
import { useState } from 'react'
import emailjs from 'emailjs-com'

const initialState = {
  name: '',
  email: '',
  message: '',
}
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    emailjs
      .sendForm(
        'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID'
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
        },
        (error) => {
          console.log(error.text)
        }
      )
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Get In Touch</h2>
              </div>
            </div>
          </div>


          <div className='col-md-8'>


<div class="container">
  <div class="row align-items-start">

    <div class="contact-item col">
     <span> <a href="tel:+1707-937-3500"><i className='fa'>{props.data ? props.data.phone : 'loading'}</i></a> (Cell, Signal, <a href="http://t.me/teddotnet">Telegram</a>, <a href="https://wa.me/17079373500">WhatsApp</a>) </span>
    </div>

    <div class="contact-item col">
     <span> <i className='fa'>{props.data ? props.data.address : 'loading'}</i></span>
    </div>

    <div class="contact-item col">
     <span> <a href="HTTPS://ZOOM.US/J/7079373500"><i className='fa'>{props.data ? props.data.zoom : 'loading'}</i> </a>(Zoom) </span>
    </div>


    <div class="contact-item col">
     <span> <i className='fa'> <a href="http://m.me/tedwilliams" target="_blank"> Facebook Messenger </a></i> </span>
    </div>

    <div class="contact-item col">
     <span> <a href="mailto:ted@ted.net"><i className='fa'>{props.data ? props.data.email : 'loading'}</i></a> </span>
    </div>

    <div class="contact-item col">
     <span> <a href="mailto:williamst@mendocinocounty.org"><i className='fa'>{props.data ? props.data.emailcounty : 'loading'}</i></a> </span>
    </div>

    <div class="contact-item col">
     <a href="https://www.snapchat.com/add/teddotnet?share_id=MDJCM0Y2&locale=en_US">
     <span><img src="county/snap_ted.jpg"/></span>
     <span> <i className='fa'></i>Snapchat</span>
     </a>
    </div>

    <div class="contact-item col">
     <span><a href="http://ted.net/vcard_ted_williams.vcf"><img src="county/contact-ted.png"/></a></span>
     <span> <i className='fa'></i>Scan or Tap to add Ted's Contact</span>
    </div>
  </div>
</div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : '/'}>
                      <i className='fa fa-twitter'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : '/'}>
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2022 Ted Williams{' '}
          </p>
        </div>
      </div>
    </div>
  )
}
